import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
// @ts-ignore @dt/config
import config from '@dt/config';
// prettier-ignore
const useGlobalStore = create()(devtools(persist(set => ({
    clearSession: () => {
        set(() => ({ sessionId: null, userSession: null }), false, "clearSession");
    },
    clearSessionAndRedirect: () => {
        set(() => ({ sessionId: null, userSession: null }), false, "clearSessionAndRedirect");
        window.location.assign(`${config.authHost}/login?forwardTo=${window.location.href}`);
    },
    loadingSession: true,
    nonce: null,
    scopedAccessToken: null,
    sessionId: null,
    setLoadingSession: by => set(() => ({ loadingSession: by }), false, "setLoadingSession"),
    setNonce: by => set(() => ({ nonce: by }), false, "setNonce"),
    setScopedAccessToken: by => set(() => ({ scopedAccessToken: by }), false, "setScopedAccessToken"),
    setSessionId: by => set(() => ({ sessionId: by }), false, "setSessionId"),
    setUserSession: by => set(() => ({ userSession: by }), false, "setUserSession"),
    userSession: null
}), {
    name: "global-storage",
})));
export default useGlobalStore;
