// sort-imports-ignore
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import { TrackingProvider } from '@dt/analytics';
import '@dt/global';
import { materialTheme } from '@dt/theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocationProvider, createHistory, createMemorySource } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as ReduxProvider } from 'react-redux';
import 'reactflow/dist/style.css';
import { createApolloClient } from './apollo';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './redux/sagas';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 50000,
    },
  },
});

export const ApplicationProviders = function ApplicationProviders({ graphQLMocks, initialRoute, children }) {
  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);
  global.__DT_STORE = store;
  runSagas(reduxSaga);

  const cache = new InMemoryCache({
    typePolicies: {
      PaginationInformation: {
        keyFields: false,
      },
      V2PolicyRulesListResponsePolicyRule: {
        // NOTE: Used to uniquely identify policy rules.
        //       Stats have to be included because this type isn't a true entity type.
        //       Meaning it changes based on the query params to the endpoint.
        keyFields: ['id', 'included_policy_violation_stats'],
      },
    },
  });

  global.__DT_APOLLO_CACHE = cache;

  // Setup apollo client.
  const client = createApolloClient(cache, graphQLMocks);

  // Setup material UI.
  const muiTheme = createTheme(materialTheme());

  // Setup navigational router.
  const history = createHistory(!initialRoute ? window : createMemorySource(initialRoute));

  return (
    <QueryClientProvider client={queryClient}>
      <LocationProvider history={history}>
        <ReduxProvider store={store}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={muiTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TrackingProvider>{children}</TrackingProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </ApolloProvider>
        </ReduxProvider>
      </LocationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
