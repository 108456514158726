import { useLocation } from '@reach/router';
import { useEffect } from 'react';
const ScrollToTopOnNavigate = ({ children }) => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return children;
};
export default ScrollToTopOnNavigate;
