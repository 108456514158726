// cli.js wants to call JS, not flow-annotated JS

const buildCspHeader = function (config, localDev = false) {
  // This is intentionally strict and disallows everything.
  const defaultSrc = ["'report-sample'", "'none'"].join(' ');
  // If you want/need to add extra hosts for a non-production environment (such as alternative hosts for our dev
  // environment), add them to config.extraImgDomains or config.extraMediaDomains.
  //
  // For a deployment to a web site that can access production data, such as www.securetheorem.com or *.demotheorem.com,
  // we should not include any non-production domains -- config.extraImgDomains and config.extraMediaDomains should be
  // left empty.
  //
  // If you need something like this for some other CSP directive, please bring it up with BJ or Alban.
  const imgSrc = [
    "'report-sample'",
    "'self'",
    'data:',
    // Our image sources
    'https://prod-horizon.static.securetheorem.com', // horizon static assets
    'https://caprica-static.securetheorem.com', // caprica's static assets  (3rd party apps icons)
    'https://prod-caprica.firebaseapp.com', // caprica's static assets (3rd party stores icons)
    'https://educate.securetheorem.com', // static file hosting for SDSU videos
    'https://disco-order-721.firebaseapp.com', // mobile issue screenshots (Dev references prod screenshot urls)
    'https://files-static.datatheorem.com/portal/', // Static file hosting (videos, icons, etc.)
    'https://storage.googleapis.com/spa-screenshots/', // Used to show screenshots of websites
    'https://storage.googleapis.com/spa-library-resources/', // Contains static assets/icons for frontend libraries related to SPA scanning
    'https://storage.googleapis.com/disco-order-721-app-store-privacy-screenshot/', // App/Play Store Answers screenshots
    // Third party image sources
    'https://lh3.googleusercontent.com', // Google Android app images
    'https://lh3.ggpht.com', // Google Android app images
    'https://lh4.ggpht.com', // Google Android app images
    'https://lh5.ggpht.com', // Google Android app images
    'https://lh6.ggpht.com', // Google Android app images
    'https://play-lh.googleusercontent.com', // Google Android app images
    'https://is1-ssl.mzstatic.com', // Apple App Store app images
    'https://is2-ssl.mzstatic.com', // Apple App Store app images
    'https://is3-ssl.mzstatic.com', // Apple App Store app images
    'https://is4-ssl.mzstatic.com', // Apple App Store app images
    'https://is5-ssl.mzstatic.com', // Apple App Store app images
    'https://logo.clearbit.com', // Website icons (for mediawatch)
    'https://d1nxzqpcg2bym0.cloudfront.net/itunes_connect/', // apptopia's app icon cache
    'https://d1nxzqpcg2bym0.cloudfront.net/google_play/', // apptopia's app icon cache
    // Misc
    'https://www.google-analytics.com',
    'https://www.googletagmanager.com',
  ]
    .concat(config.extraImgDomains)
    .join(' ');
  const mediaSrc = [
    'https://educate.securetheorem.com', // static file hosting for SDSU videos
    'https://files-static.datatheorem.com/portal/', // Static file hosting (videos, icons, etc.)
  ]
    .concat(config.extraMediaDomains)
    .join(' ');

  const styleSrc = [
    "'report-sample'",
    "'self'",
    "'unsafe-inline'",
    // Fonts
    'https://fonts.googleapis.com',
    'https://use.typekit.net',
    'https://p.typekit.net',
  ].join(' ');

  // script-src is our primary defense against XSS exploits. We do not allow unsafe-inline or unsafe-eval here.
  // Excluding unsafe-inline prevents <script> tags from directly including JavaScript inline in a web page, which
  // mitigates a lot of XSS attack vectors. Excluding unsafe-eval disallows potentially dangerous cases for JavaScript
  // code directly reads some data and then tries to interpret that data as JavaScript.
  const scriptSrc = [
    "'report-sample'",
    "'self'",
    `https://${config.sentryHost}`,
    'https://www.google-analytics.com',
    'https://www.googletagmanager.com',
  ].join(' ');

  // APIs (fetch, XHR, web sockets)
  const connectSrcBase = [
    "'report-sample'",
    "'self'",
    'blob:',
    config.publicAPI, // Where our portal APIs are hosted
    config.googleStorage, // Used to upload a PO Quote file (eg, sends an email to finance)
    config.uploadHost, // The primary mobile app upload host
    // https://appupload.securetheorem.com is an emergency alternative to the uploadHost, that we continue to support
    // for a few customers.
    'https://appupload.securetheorem.com',
    `https://${config.sentryHost}`, // Sentry analytics
    'https://www.google-analytics.com',
    'https://www.googleapis.com',
  ].join(' ');
  // For local development, we need to add a few extra origins to allow webpack's web sockets to work.
  const connectSrc = !localDev
    ? connectSrcBase
    : [
        connectSrcBase,
        'ws://localhost:7009',
        'http://acheronlocal.com:7009',
        'ws://acheronlocal.com:7009',
        'http://acheronlocal.com:7004',
        'ws://acheronlocal.com:7004',
      ].join(' ');

  const frameSrc = [
    "'report-sample'",
    "'self'",
    'blob:',
    'https://educate.securetheorem.com', // static file hosting for SDSU videos. PDFs are hosted in frames
    'https://www.youtube-nocookie.com', // allow SDSU to embed Youtube videos in frames
    'https://zoom.us', // allow SDSU to embed Zoom recordings in frames
    config.authHost, // needed to be able to use message passing with dt-auth, whose code runs in a hidden iframe.
  ]
    .concat(config.extraFrameDomains)
    .join(' ');

  const fontSrc = [
    "'report-sample'",
    "'self'",
    'data:',
    'https://fonts.gstatic.com',
    'https://use.typekit.net',
    'https://p.typekit.net',
  ].join(' ');
  const objectSrc = ["'report-sample'", "'none'"].join(' ');
  const frameAncestors = `'self'`;
  // TODO: Add the sentry_environment query parameter with a sentry environment (however, we should treat demotheorem as
  // different from production)
  const reportUri = `https://o1421491.ingest.sentry.io/api/6767243/security/?sentry_key=e958eee4d16443b4a6674cda8c008ca7`;

  const csp = [
    `default-src ${defaultSrc}`,
    `img-src ${imgSrc}`,
    `media-src ${mediaSrc}`,
    `style-src ${styleSrc}`,
    `script-src ${scriptSrc}`,
    `connect-src ${connectSrc}`,
    `frame-src ${frameSrc}`,
    `font-src ${fontSrc}`,
    `object-src ${objectSrc}`,
    `frame-ancestors ${frameAncestors}`,
    `report-uri ${reportUri}`,
  ].join(' ; ');

  return csp;
};

const buildCspHeaderNonHtml = function () {
  // Currently doesn't need anything from our config
  const defaultSrc = `'report-sample' 'none'`;
  const imgSrc = `'report-sample' 'self'`; // for favicon in firefox when viewing an image directly
  const styleSrc = `'report-sample' 'unsafe-inline'`; // chrome applies inline CSS to images that are directly viewed
  const reportUri = `https://o1421491.ingest.sentry.io/api/6767243/security/?sentry_key=e958eee4d16443b4a6674cda8c008ca7`;

  const csp = [
    `default-src ${defaultSrc}`,
    `img-src ${imgSrc}`,
    `style-src ${styleSrc}`,
    `report-uri ${reportUri}`,
  ].join(' ; ');

  return csp;
};

module.exports = {
  buildCspHeader: buildCspHeader,
  buildCspHeaderNonHtml: buildCspHeaderNonHtml,
};
