import useGoogleLogin from '@hooks/login/useGoogleLogin';
import useOtpLogin from '@hooks/login/useOtpLogin';
import getLoginInfo from '@queries/getLoginInfo';
import useGlobalStore from '@store/global';
import { useQuery } from '@tanstack/react-query';
import initiateSamlLogin from '@utils/login/initiateSamlLogin';
import { useEffect, useState } from 'react';
const useLogin = ({ email, otp, rememberEmail }) => {
    const { clearSession } = useGlobalStore();
    const { initiateGoogleLogin } = useGoogleLogin();
    const { initiateOtpLogin, error: errorOtpLogin } = useOtpLogin();
    const [errorStrategy, setErrorStrategy] = useState();
    const [showOtp, setShowOtp] = useState(false);
    const queryAuthStrategy = useQuery({
        enabled: false,
        queryFn: () => getLoginInfo({ variables: { email } }),
        queryKey: ['get-login-info', { email, otp }],
    });
    const { data, error: errorSubmit, isLoading, refetch: fetchAuthStrategy } = queryAuthStrategy;
    const submit = () => {
        setErrorStrategy(undefined);
        if (showOtp) {
            initiateOtpLogin({ email, otp, rememberEmail });
        }
        else {
            fetchAuthStrategy();
        }
    };
    const authStrategy = data?.auth_strategy;
    const error = errorStrategy || errorSubmit || errorOtpLogin;
    useEffect(() => {
        clearSession();
    }, []); // eslint-disable-line
    useEffect(() => {
        if (!authStrategy)
            return;
        switch (authStrategy) {
            case 'GOOGLE_ACCOUNT':
                initiateGoogleLogin({ email, rememberEmail });
                break;
            case 'SAML':
                if (data?.saml?.sp_id) {
                    initiateSamlLogin({ email, rememberEmail, samlSpId: data?.saml?.sp_id });
                }
                else {
                    setErrorStrategy({ message: 'Error validating SAML workflow.' });
                }
                break;
            case 'OTP':
                setShowOtp(true);
                break;
            default:
                setErrorStrategy({ message: 'Invalid login strategy' });
        }
    }, [authStrategy, email]); // eslint-disable-line react-hooks/exhaustive-deps
    return {
        error,
        isLoading,
        showOtp,
        submit,
    };
};
export default useLogin;
