import dtFetch from '@utils/dtFetch';
import { z } from 'zod';
const AUTH_STRATEGY = z.enum(['GOOGLE_ACCOUNT', 'OTP', 'SAML']);
const SAML = z.object({
    sp_id: z.string(),
});
const LoginInfo = z.object({
    auth_strategy: AUTH_STRATEGY,
    saml: z.optional(SAML),
});
const getLoginInfo = async ({ variables }) => {
    const baseUrl = ':sevenhell/v2/login_info/:email';
    const response = await dtFetch({
        schema: LoginInfo,
        url: `${baseUrl}`,
        variables,
    });
    return response;
};
export default getLoginInfo;
