// sort-imports-ignore
import paginate, { paginateToEnd } from '@dt/ahura/src/redux/sagas/util/paginate';
import ContactPublisherRequestStateEnum from '@dt/enums/ContactPublisherRequestStateEnum';
import { setTemporaryToken as fetchSetTemporaryToken } from '@dt/fetch';
import { Raven } from '@dt/global';
import { Actions as NotificationsActions } from '@dt/notifications';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callPromise, callSaga, select } from '@dt/redux-saga-wrapped-effects';
import { alerts_filter_config, contact_publisher_requests, favorite_apps, mobile_apps } from '@dt/user-api/openscan';
import * as support_requests from '@dt/user-api/support_requests';
import { tryAndParseErrorMessage } from '@dt/user-api/util';
import useGlobalStore from '@store/global';
import { put, race, spawn, take, takeEvery } from 'redux-saga/effects';
import {
  addAppToOpenscanWatchListGroup,
  contactPublisherRequest,
  contactPublisherRequestError,
  contactPublisherRequestLoading,
  contactPublisherRequestReceived,
  disabledWatchListInquire,
  openscanConfigReceived,
  openscanErrorOccurred,
  openscanMobileAppsRecieved,
  openscanRuleUpdated,
  openscanWatchlistLoading,
  receivedApp,
  removeAppFromOpenscanWatchList,
  removeAppFromOpenscanWatchListGroup,
  requestApp,
  setTemporaryToken,
  setTemporaryTokenSuccess,
  updateApp,
} from '../actions';
import { appFromId } from '../selectors/openscan';
import { FavoriteAppsEndpoint } from './endpoints';
import {
  watchForOpenscanConfigReceived as watchForPolicyViolationOpenscanConfigReceived,
  watchForOpenscanMobileAppsReceived as watchForPolicyViolationsOpenscanMobileAppsReceived,
} from './policyViolations';

export function* saga() {
  const { userSession } = useGlobalStore.getState();
  const { temporaryToken } = yield race({
    temporaryToken: take(setTemporaryToken.toString()),
  });

  if (temporaryToken) {
    // eslint-disable-next-line no-console
    console.log(`Setting temporary token: ${temporaryToken.payload}`);
    fetchSetTemporaryToken(temporaryToken.payload);

    yield spawn(watchForRequestApp);
    yield put(setTemporaryTokenSuccess());
    return;
  }

  if (!userSession?.account_info.toggles || !userSession?.account_info.toggles.openscan) {
    return;
  }
  yield spawn(watchForRequestApp);
  yield spawn(watchForPolicyViolationsOpenscanMobileAppsReceived);
  yield spawn(watchForPolicyViolationOpenscanConfigReceived);
  yield spawn(watchForAddAppToWatchlistGroup);
  yield spawn(watchForRemoveAppFromWatchlistGroup);
  yield spawn(watchForRemoveAppFromWatchlist);
  yield spawn(watchForRuleUpdate);
  yield spawn(watchForContactPublisherRequest);
  yield spawn(watchForDisabledWatchListInquire);
  yield spawn(watchForErrors);
  if (window.location.pathname.startsWith('/openscan')) {
    yield spawn(loadAllWatchlistApps);
    yield spawn(loadOpenscanConfigAtStart);
  }
}

export function* watchForRequestApp() {
  yield takeEvery(requestApp.toString(), function* (action) {
    const { id } = action.payload;

    try {
      const app = yield* callPromise(mobile_apps.get, id);

      if (app) {
        yield put(updateApp(app));
        yield put(receivedApp(id));
      }
    } catch (err) {
      // TODO@nw: Notification for loading app.
    }
  });
}

export function* watchForAddAppToWatchlistGroup() {
  yield takeEvery(addAppToOpenscanWatchListGroup.toString(), function* ({ payload }) {
    try {
      const app = yield* callPromise(favorite_apps.add, payload.id, payload.group);
      if (!app) {
        throw new Error('Could not load openscan app');
      }
      yield put(updateApp(app));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  });
}

export function* watchForRemoveAppFromWatchlist() {
  yield takeEvery(removeAppFromOpenscanWatchList.toString(), function* ({ payload }) {
    try {
      const app = yield* callPromise(favorite_apps.remove, payload.id);
      if (!app) {
        throw new Error('Could not remove app');
      }
      yield put(updateApp(app));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  });
}

export function* watchForRemoveAppFromWatchlistGroup() {
  yield takeEvery(removeAppFromOpenscanWatchListGroup.toString(), function* ({ payload }) {
    try {
      const app = yield* callPromise(favorite_apps.remove, payload.id, payload.group);
      if (!app) {
        throw new Error('Could not remove app from favorites');
      }
      yield put(updateApp(app));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  });
}

export function* watchForContactPublisherRequest() {
  yield takeEvery(contactPublisherRequest.toString(), function* ({ payload }) {
    try {
      let app = yield* select(appFromId(payload.id), {});

      if (!app) {
        throw new Error('This openscan app was not found /1');
      }

      yield put(
        contactPublisherRequestLoading([
          {
            ...app,
            contactPublisherRequestState: ContactPublisherRequestStateEnum.LOADING,
          },
        ]),
      );

      yield* callPromise(contact_publisher_requests.create, {
        additional_recipients: payload.additional_recipients,
        mobile_app_id: payload.id,
      });
      app = yield* select(appFromId(payload.id), {});

      if (!app) {
        throw new Error('This openscan app was not found /2');
      }

      yield put(
        contactPublisherRequestReceived([
          {
            ...app,
            contactPublisherRequestState: ContactPublisherRequestStateEnum.RECEIVED,
          },
        ]),
      );
    } catch (err) {
      const app = yield* select(appFromId(payload.id), {});

      if (!app) {
        throw new Error('This openscan app was not found /2');
      }

      yield put(
        contactPublisherRequestError([
          {
            ...app,
            contactPublisherRequestState: ContactPublisherRequestStateEnum.ERROR,
          },
        ]),
      );
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  });
}

const favoriteAppsPageGetter = function* (params) {
  return yield* callPromise(favorite_apps.list, params || { cursor: '' });
};

function* getWatchlistApps() {
  try {
    yield put(openscanWatchlistLoading());

    const response = yield* callSaga(paginate, FavoriteAppsEndpoint, {}, favoriteAppsPageGetter);

    if (response) {
      yield put(openscanMobileAppsRecieved(response.openscan_mobile_apps || []));
      return response.openscan_mobile_apps;
    } else {
      yield put(openscanErrorOccurred('An unknown error occurred (OpenScan Initialize)'));
    }
  } catch (e) {
    yield put(openscanErrorOccurred(tryAndParseErrorMessage(e)));
  }
}

export function* loadAllWatchlistApps() {
  return yield* withProgressIndicator(function* () {
    yield* callSaga(paginateToEnd, getWatchlistApps, FavoriteAppsEndpoint, {}, {});
  }, FavoriteAppsEndpoint);
}

export function* loadOpenscanConfigAtStart() {
  yield* withProgressIndicator(function* () {
    try {
      const config = yield* callPromise(alerts_filter_config.get);

      yield put(openscanConfigReceived(config));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  }, 'openscan');
}

export function* watchForRuleUpdate() {
  yield takeEvery(openscanRuleUpdated.toString(), function* ({ payload }) {
    try {
      const { config } = payload;
      const newConfig = yield* callPromise(alerts_filter_config.replace, config);
      yield put(openscanConfigReceived(newConfig));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  });
}

export function* watchForDisabledWatchListInquire() {
  yield takeEvery(disabledWatchListInquire.toString(), function* ({ payload }) {
    try {
      const currentUser = yield* select(state => state.currentUser);

      const user = {
        first_name: 'N/A',
        last_name: 'N/A',
        login_email: 'N/A',
        role: 'N/A',
        ...currentUser,
      };

      const app = {
        bundle_id: 'N/A',
        name: 'N/A',
        platform: 'N/A',
        ...payload.app,
      };

      const body = `
    First Name: ${user.first_name}
    Last Name: ${user.last_name}
    Notification Email: ${user.notification_email || user.login_email}
    Role: ${user.role}
    App Name: ${app.name} (${app.platform})
    Bundle ID: ${app.bundle_id}
  `;

      yield* callPromise(support_requests.create, {
        body,
        subject: 'Disabled WatchList',
      });

      yield put(
        NotificationsActions.requestNotifyUser({
          text: 'A representative from Data Theorem will be contacting you shortly.',
        }),
      );
    } catch (err) {
      Raven.captureException(err, {
        extra: { msg: 'watchForDisabledWatchListInquire in inquire saga' },
      });

      yield put(
        NotificationsActions.requestNotifyUser({
          text: 'There was an error. Please contact support@datatheorem.com',
        }),
      );

      throw err;
    }
  });
}

export function* watchForErrors() {
  yield takeEvery(openscanErrorOccurred.toString(), function* ({ payload }) {
    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test')
      console.warn('An error has occured in a saga=', payload);
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'An error has occured. Please contact support@datatheorem.com',
      }),
    );
  });
}
