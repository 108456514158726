import { useEffect } from 'react';
const pathTitleMap = {
    '/api': 'API Secure',
    '/cloud': 'Cloud Secure',
    '/dashboard': 'Dashboard',
    '/devsecops': 'DevSecOps',
    '/management': 'Management',
    '/mobile': 'Mobile Secure',
    '/openscan': 'Supply Chain Security',
    '/policies': 'Policies',
    '/supply-chain': 'Supply Chain Secure',
    '/web': 'Web Secure',
};
const DTPageTitle = ({ children }) => {
    const { pathname } = window.location;
    useEffect(() => {
        const title = Object.entries(pathTitleMap).find(([path]) => pathname.startsWith(path))?.[1] || '';
        document.title = `${title ? `${title} | ` : ''}Data Theorem`;
    }, [pathname]);
    return children;
};
export default DTPageTitle;
