import { NotFoundPage } from '@dt/components';
import { Redirect, Router } from '@reach/router';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/login/Login';
import Oauth2Handler from './components/login/Oauth2Handler';
import SamlHandler from './components/login/SamlHandler';
import routesPageContent from './routes';
import { Fragment } from 'react';

const ApplicationRouting = () => {
  return (
    <Router>
      <Login path="/login" />
      <Oauth2Handler path="/login/oauth2callback" />
      <SamlHandler path="/login/samlcallback" />

      <ProtectedRoute path="/">
        {Object.entries(routesPageContent).map(([route, Destination]) => (
          <Fragment key={route}>
            {typeof Destination === 'string' && <Redirect from={route} noThrow to={Destination} />}

            {typeof Destination !== 'string' && <Destination path={route} />}
          </Fragment>
        ))}

        <Redirect from="/" noThrow to="/dashboard" />

        <NotFoundPage default />
      </ProtectedRoute>
    </Router>
  );
};

export default ApplicationRouting;
