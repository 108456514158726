// @ts-ignore TODO-IMPLEMENT-DT-CONFIG
import config from '@dt/config';
import useGlobalStore from '@store/global';
import fetchUserAccount from '@utils/fetchUserAccount';
import isTrustedDomain from '@utils/isTrustedDomain';
import defaultRedirect from '@utils/login/defaultRedirect';
import setEmailLocalStorage from '@utils/login/setEmailLocalStorage';
import qs from 'query-string';
import { useState } from 'react';
const sessionByOTP = async ({ email, otp }) => {
    const r = await fetch(`${config.sevenhellApiBaseUrl}/v2/session`, {
        body: JSON.stringify({ email, password: otp }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
    });
    return r.json();
};
const useOtpLogin = () => {
    const { setSessionId, setUserSession } = useGlobalStore();
    const [error, setError] = useState();
    const initiateOtpLogin = async ({ email, otp, rememberEmail }) => {
        const parsed = qs.parse(location.hash);
        const { state } = parsed;
        const parsedState = qs.parse(String(state));
        const returnedForwardTo = String(parsedState.forwardTo);
        try {
            const sessionInfo = await sessionByOTP({ email, otp });
            const sessionId = sessionInfo.session_id;
            if (sessionId) {
                const r = await fetchUserAccount({ sessionId });
                const userSession = await r.json();
                setSessionId(sessionId);
                setUserSession(userSession);
                if (returnedForwardTo && isTrustedDomain(String(returnedForwardTo), config.trustedOrigins)) {
                    setEmailLocalStorage({ email, rememberEmail });
                    window.location.assign(String(returnedForwardTo));
                }
                else {
                    defaultRedirect();
                }
            }
            else {
                setError(new Error('Invalid One Time Password'));
            }
        }
        catch (e) {
            console.error(e);
            setError(new Error('Error completing OTP Login'));
        }
    };
    return { error, initiateOtpLogin };
};
export default useOtpLogin;
