/**
 * Taken from "Parsing a URI Reference with a Regular Expression"
 * at https://tools.ietf.org/html/rfc2396#appendix-B
 */
const parseURL = (url) => {
    const pattern = RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?');
    const matches = url.match(pattern);
    if (matches) {
        return {
            fragment: (matches[9] || '').toLowerCase(),
            host: (matches[4] || '').toLowerCase(),
            path: (matches[5] || '').toLowerCase(),
            protocol: (matches[2] || '').toLowerCase(),
            query: (matches[7] || '').toLowerCase(),
        };
    }
    else {
        return null;
    }
};
const isExactDomainMatch = (a, b) => {
    return Boolean(a.host && b.host && a.protocol && b.protocol && a.host === b.host && a.protocol === b.protocol);
};
const isSubdomainMatch = (given, trusted) => {
    return Boolean(given.host &&
        given.protocol &&
        trusted.host &&
        trusted.protocol &&
        given.protocol === trusted.protocol &&
        given.host.endsWith(trusted.host.replace(/^\*/i, '')));
};
const isTrusted = (givenURL, trustedOrigin) => {
    const given = parseURL(givenURL);
    const trusted = parseURL(trustedOrigin);
    return given && trusted ? isExactDomainMatch(given, trusted) || isSubdomainMatch(given, trusted) : false;
};
const isTrustedDomain = (url, tos) => {
    return tos.map(t => t.url).some(t => isTrusted(url, t));
};
export default isTrustedDomain;
