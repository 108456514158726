// @ts-ignore TODO-IMPLEMENT-DT-CONFIG
import config from '@dt/config';
const fetchUserAccount = async ({ sessionId, tokenId }) => {
    const url = `${config.sevenhellApiBaseUrl}/v2/user_account`;
    let authHeader = '';
    if (sessionId) {
        authHeader = `Session ${sessionId}`;
    }
    else if (tokenId) {
        authHeader = `ScopedAccessToken ${tokenId}`;
    }
    const headers = {
        Authorization: authHeader,
    };
    return fetch(url, { headers });
};
export default fetchUserAccount;
