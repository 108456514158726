import { Actions as NotificationsActions } from '@dt/notifications';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useDispatch } from 'react-redux';

const Notifier = ({ notifications }) => {
  const dispatch = useDispatch();
  const notification = notifications.length ? notifications[notifications.length - 1] : null;

  const handleClickDismiss = notification => {
    if (!notification) {
      throw new Error('Tried to dismiss nonexistant notification');
    }

    dispatch(NotificationsActions.dismissNotificationClicked(notification));
  };

  if (!notification) {
    return null;
  }

  return notification.severity ? (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={5000}
      onClose={(e, snackbarCloseReason) => {
        if (snackbarCloseReason === 'timeout') {
          handleClickDismiss(notification);
        }
      }}
      open={true}
    >
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" onClick={() => handleClickDismiss(notification)} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={notification.severity}
        variant="filled"
      >
        {notification.text}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      action={
        <Button color="secondary" onClick={() => handleClickDismiss(notification)}>
          Dismiss
        </Button>
      }
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={5000}
      message={notification.text}
      onClose={() => {}}
      open={true}
    />
  );
};

export default Notifier;
