import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
// @ts-ignore
import config from '@dt/config';
import extractSearchParam from '@utils/extractSearchParam';
import fetchUserAccount from '@utils/fetchUserAccount';
import isTrustedDomain from '@utils/isTrustedDomain';
import defaultRedirect from '@utils/login/defaultRedirect';
import Cookies from 'js-cookie';
import useGlobalStore from '../../store/global';
import LoginError from './LoginError';
const SamlHandler = () => {
    const [error, setError] = useState(false);
    const { setUserSession, setSessionId } = useGlobalStore();
    useEffect(() => {
        async function createSession({ returnedForwardTo }) {
            const sessionBase64 = Cookies.get('session_info');
            if (sessionBase64) {
                const decoded = window.atob(sessionBase64);
                const parsed = JSON.parse(decoded);
                const sessionInfo = { session_expiration: '', session_id: '', ...parsed };
                const sessionId = sessionInfo.session_id;
                if (sessionId) {
                    try {
                        const r = await fetchUserAccount({ sessionId });
                        const userSession = await r.json();
                        setSessionId(sessionId);
                        setUserSession(userSession);
                        if (returnedForwardTo && isTrustedDomain(String(returnedForwardTo), config.trustedOrigins)) {
                            window.location.assign(String(returnedForwardTo));
                        }
                        else {
                            defaultRedirect();
                        }
                    }
                    catch (e) {
                        console.error(e);
                        setError(true);
                    }
                }
            }
            else {
                setError(true);
            }
        }
        const returnedForwardTo = String(extractSearchParam({ key: 'forwardTo' }));
        createSession({ returnedForwardTo });
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
    if (error) {
        return _jsx(LoginError, {});
    }
    return (_jsx(Box, { alignItems: "center", bgcolor: "white", display: "flex", height: "100vh", justifyContent: "center", width: "100vw", children: _jsx(CircularProgress, {}) }));
};
export default SamlHandler;
