// @ts-ignore TODO-IMPLEMENT-DT-CONFIG
import config from '@dt/config';
import useGlobalStore from '@store/global';
import createNonce from '@utils/createNonce';
import extractSearchParam from '@utils/extractSearchParam';
import setEmailLocalStorage from '@utils/login/setEmailLocalStorage';
const defaults = {
    client_id: config.googleClientId,
    include_granted_scopes: 'true',
    oauth_endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    prompt: 'select_account',
    response_type: 'token',
    scope: 'https://www.googleapis.com/auth/userinfo.email',
};
const getRelayState = ({ nonce }) => {
    const forwardTo = extractSearchParam({ key: 'forwardTo' }) || '/';
    const u = new URLSearchParams();
    u.set('forwardTo', forwardTo);
    u.set('nonce', nonce);
    return u.toString();
};
const getGoogleForwardURL = ({ email, nonce }) => {
    const location = window.location;
    const { scope, client_id, include_granted_scopes, oauth_endpoint, prompt, response_type } = defaults;
    const redirect_uri = `${location.protocol}//${location.host}/login/oauth2callback`;
    const state = getRelayState({ nonce });
    const url = new URLSearchParams();
    url.set('scope', scope);
    url.set('include_granted_scopes', include_granted_scopes);
    url.set('state', state);
    url.set('redirect_uri', redirect_uri);
    url.set('response_type', response_type);
    url.set('prompt', prompt);
    url.set('client_id', client_id);
    url.set('login_hint', email);
    return `${oauth_endpoint}?${url.toString()}`;
};
const useGoogleLogin = () => {
    const { setNonce } = useGlobalStore();
    const initiateGoogleLogin = ({ email, rememberEmail }) => {
        const nonce = createNonce();
        setNonce(nonce);
        const forwardUrl = getGoogleForwardURL({ email, nonce });
        setEmailLocalStorage({ email, rememberEmail });
        window.location.assign(forwardUrl);
    };
    return { initiateGoogleLogin };
};
export default useGoogleLogin;
