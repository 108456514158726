// @ts-ignore TODO-IMPLEMENT-DT-CONFIG
import config from '@dt/config';
import extractSearchParam from '@utils/extractSearchParam';
import setEmailLocalStorage from './setEmailLocalStorage';
const getSAMLForwardURL = (providerId) => {
    const forwardTo = extractSearchParam({ key: 'forwardTo' }) || '/';
    const url = new URLSearchParams();
    url.set('forwardTo', forwardTo);
    const returnTo = `/login/samlcallback?${url.toString()}`;
    url.set('return_to', returnTo);
    return `${config.samlServiceURL}/${providerId}?${url.toString()}`;
};
const initiateSamlLogin = ({ samlSpId, email, rememberEmail }) => {
    const samlForwardURL = getSAMLForwardURL(samlSpId);
    setEmailLocalStorage({ email, rememberEmail });
    window.location.assign(samlForwardURL);
};
export default initiateSamlLogin;
