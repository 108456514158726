import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@mui/material';
import { useRef, useState } from 'react';
const ImageLoaderPlaceholder = ({ src, alt, width = 24, height, style }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageRef = useRef(null);
    return (_jsxs(_Fragment, { children: [!imageLoaded && (_jsx(Skeleton, { height: height ?? width, style: { borderRadius: 8, marginRight: 8 }, variant: "rectangular", width: width })), _jsx("img", { alt: alt, height: height ?? width, onLoad: () => {
                    setImageLoaded(true);
                }, ref: imageRef, src: src, style: {
                    ...style,
                    display: imageLoaded ? 'block' : 'none',
                }, width: width })] }));
};
export default ImageLoaderPlaceholder;
