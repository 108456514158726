import { setTemporaryToken } from '@dt/horizon-api';
import { getTemporaryAccessTokenUserAccount } from '@dt/session';
import useGlobalStore from '@store/global';
import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/*
 * Used for secure share pages.
 * The temporary access token is prepared here.
 *
 * @param token - Temporary access token.
 *
 * @example
 *   const Page = function Page() {
 *     const { loading, error } = useTemporaryAccessToken({ token });
 *     if (error) return <PageError />;
 *     if (loading || !data) return <PageSkeleton />;
 *     return <PageContent />
 *   };
 */
export const useTemporaryAccessToken = ({ token }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { clearSessionAndRedirect } = useGlobalStore();
  const isReady = useSelector(state => state.ready);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (token) {
      setTemporaryToken(token);
      setIsMounted(true);
      if (token && isReady && isMounted) {
        getTemporaryAccessTokenUserAccount(token)
          .then(result => {
            setData({
              token,
              ...result,
            });
          })
          .catch(e => {
            Raven.captureException(e, {
              extra: { msg: 'An error occurred fetching the user account.' },
            });
            setError(e);
            if (!token) {
              clearSessionAndRedirect();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [token, isMounted, isReady]);

  return { data, error, loading };
};

/*
 * Compares the provided actor's authenticated session with provided access controls.
 *
 * If the user doesn't meet those access controls invalid is returned.
 * If this is a cross-product view, ignore the access list and defer to B/E.
 * Otherwise the user has access.
 *
 * This hook is designed to be a simple access control check against the requested
 * user session.
 */
export const useAuthorization = (userSession, accessControls, crossProductView) => {
  let isAuthorized = true;
  if (userSession) {
    if (typeof crossProductView === 'undefined' || !crossProductView) {
      for (const accessControl of accessControls) {
        if (!userSession.current_user[accessControl]) {
          isAuthorized = false;
          break;
        }
      }
    }
  } else {
    isAuthorized = false;
  }

  return { isAuthorized };
};
