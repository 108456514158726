import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-ignore
import DTLogo from '@dt/brand/logo.png';
import { Box, Stack } from '@mui/material';
import ImageLoaderPlaceholder from '../ImageLoaderPlaceholder';
import Text from '../Text';
const LoginError = () => {
    return (_jsx(Box, { bgcolor: "white", height: "100vh", children: _jsx(Box, { alignItems: "center", display: "flex", height: "75%", justifyContent: "center", children: _jsxs(Stack, { alignItems: "center", spacing: 2, children: [_jsx(ImageLoaderPlaceholder, { alt: "DT-logo", src: DTLogo, width: "170px" }), _jsx(Text, { component: "h1", variant: "titleL", children: "Error" }), _jsxs(Text, { children: ["An error was identified during your login process. Please contact", ' ', _jsx(Text, { component: "span", variant: "link", children: _jsx("a", { href: "mailto:info@datatheorem.com", children: "info@datatheorem.com" }) })] })] }) }) }));
};
export default LoginError;
