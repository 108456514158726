import { jsx as _jsx } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import useGlobalStore from '@store/global';
import qs from 'query-string';
import { useEffect, useState } from 'react';
// @ts-ignore
import config from '@dt/config';
import fetchUserAccount from '@utils/fetchUserAccount';
import isTrustedDomain from '@utils/isTrustedDomain';
import defaultRedirect from '@utils/login/defaultRedirect';
import LoginError from './LoginError';
const postSessionByGoogleToken = async ({ token }) => {
    const r = await fetch(`${config.sevenhellApiBaseUrl}/v2/session`, {
        headers: { Authorization: `Bearer ${token}` },
        method: 'POST',
    });
    return r.json();
};
const Oauth2Handler = () => {
    const { nonce, setSessionId, setUserSession } = useGlobalStore();
    const [error, setError] = useState(false);
    useEffect(() => {
        async function createSession({ token, returnedForwardTo }) {
            try {
                const sessionInfo = await postSessionByGoogleToken({ token });
                const sessionId = sessionInfo.session_id;
                if (sessionId) {
                    const r = await fetchUserAccount({ sessionId });
                    const userSession = await r.json();
                    setSessionId(sessionId);
                    setUserSession(userSession);
                    if (returnedForwardTo && isTrustedDomain(String(returnedForwardTo), config.trustedOrigins)) {
                        window.location.assign(String(returnedForwardTo));
                    }
                    else {
                        defaultRedirect();
                    }
                }
                else {
                    defaultRedirect();
                }
            }
            catch (e) {
                console.error(e);
                setError(true);
            }
        }
        const parsed = qs.parse(location.hash);
        const { state } = parsed;
        const parsedState = qs.parse(String(state));
        const token = String(parsed.access_token);
        const returnedForwardTo = String(parsedState.forwardTo);
        const returnedNonce = parsedState.nonce;
        if (!token || returnedNonce !== nonce) {
            setError(true);
        }
        else {
            createSession({ returnedForwardTo, token: token });
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
    if (error) {
        return _jsx(LoginError, {});
    }
    return (_jsx(Box, { alignItems: "center", bgcolor: "white", display: "flex", height: "100vh", justifyContent: "center", width: "100vw", children: _jsx(CircularProgress, {}) }));
};
export default Oauth2Handler;
