import useGlobalStore from '@store/global';
import { useEffect } from 'react';
// @ts-ignore
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import fetchUserAccount from '../util/fetchUserAccount';
// @ts-ignore
import { loadAllMobileAppsAtLogin } from '../redux/actions';
const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loadingSession, userSession, setLoadingSession } = useGlobalStore();
    useEffect(() => {
        const loadUser = async () => {
            try {
                const r = await fetchUserAccount({});
                if (r.status === 401) {
                    if (location.pathname !== '/login') {
                        navigate(`/login?forwardTo=${window.location.href}`);
                    }
                }
                else {
                    return r.json();
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoadingSession(false);
            }
        };
        if (!userSession) {
            loadUser();
        }
    }, [navigate, setLoadingSession, userSession]);
    useEffect(() => {
        dispatch(loadAllMobileAppsAtLogin());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    if (!userSession || loadingSession) {
        return null;
    }
    return children;
};
export default ProtectedRoute;
