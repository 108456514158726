import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorOutline } from '@mui/icons-material';
import { FormControl, FormHelperText, InputAdornment, InputBase, InputLabel, Stack, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import Text from './Text';
const StyledInputLabel = styled(InputLabel)({
    transform: 'none',
});
const StyledInput = styled(InputBase)(({ theme, error }) => ({
    '&.MuiInputBase-root': {
        backgroundColor: error ? theme.palette.red[50] : 'white',
        border: `0.1px solid ${error ? theme.palette.red[50] : theme.palette.gray[40]}`,
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 4px 0px rgb(18 18 18 / 5%)',
        fontSize: 16,
        height: 40,
        padding: '10px 12px',
        position: 'relative',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    },
    '&.MuiInputBase-root.Mui-focused': {
        backgroundColor: error ? theme.palette.red[50] : theme.palette.gray[50],
    },
    'label + &': {
        marginTop: theme.spacing(3),
    },
}));
const FormInput = ({ title, error, rules, control, errorMessage, id, StartIcon }) => {
    return (_jsx(Stack, { width: "100%", children: _jsxs(FormControl, { error: !!error, children: [_jsx(StyledInputLabel, { htmlFor: "email", shrink: true, sx: { padding: '0 2px' }, children: _jsx(Text, { variant: "titleXS", children: title }) }), _jsx(Controller, { control: control, name: id, render: ({ field }) => (_jsx(StyledInput, { id: id, ...field, error: error, fullWidth: true, placeholder: title, startAdornment: StartIcon ? _jsx(InputAdornment, { position: "start", children: StartIcon }) : null })), rules: rules }), error && (_jsx(FormHelperText, { id: id, children: _jsxs(Stack, { alignItems: "center", direction: "row", spacing: 0.5, children: [_jsx(ErrorOutline, { sx: { fontSize: 16 } }), _jsx("p", { children: errorMessage })] }) }))] }) }));
};
export default FormInput;
