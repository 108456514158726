// sort-imports-ignore
import '@dt/global';
import 'reactflow/dist/style.css';
import { ApplicationProviders as DTApplicationProviders } from './ApplicationProviders';
import DTApplication from './DTApplication';

export const Application = ({ initialRoute, graphQLMocks }) => {
  return (
    <DTApplicationProviders graphQLMocks={graphQLMocks} initialRoute={initialRoute}>
      <DTApplication />
    </DTApplicationProviders>
  );
};

// TMP to not break the tests
export const ApplicationProviders = DTApplicationProviders;

export default Application;
